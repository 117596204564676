<template>
	<GridTextBox
		:background-color="data.backgroundColor"
		v-html="data.content"
	/>
</template>

<script>
import GridTextBox from '@zyro-inc/site-modules/components/elements/text-box/GridTextBox.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'GridTextBoxProviderUser',

	components: {
		GridTextBox,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
	},
});
</script>
